import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Disqus } from 'gatsby-plugin-disqus'
import { Box } from '@pw/ui'

const Comments = ({ id, title, url, ...props }) => {
  const config = {
    url,
    identifier: id,
    title: title,
  }

  return (
    <Box {...props}>
      <Disqus config={config} />
    </Box>
  )
}

const commentstyles = () =>
  css(`
    #disqus_thread {
      position: relative;

      &:before {
        content: '';
        background-color: #fff;
        display: block;
        width: 100%;
        height: 40px;
        position: absolute;
        left: 0;
        top: 45px;
        right: 0;
      }
    }
  `)

const StyledComments = styled(Comments)(commentstyles)

export default StyledComments
