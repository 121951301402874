import React from 'react'
import { graphql } from 'gatsby'
import { Box } from '@pw/ui'
import get from '@pw-utils/get'

import PostSingle from '../components/Posts/Single'
import Comments from '../components/Comments'

import { Layout, Container } from '../components/Layout'
import Sidebar from '../components/Sidebar'

export const PostTemplate = ({ data, location }) => {
  const post = get(data, 'contentfulBlogPost', {})

  return (
    <Layout location={location} seo={post.seo}>
      <Container
        d="flex"
        py={{ base: 10, md: 20 }}
        px={{ base: 0, md: 4 }}
        maxW={{ md: '1200px', lg: '1400px' }}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Box flex="3" pr={{ md: '10' }}>
          <PostSingle post={post} location={location} />

          <Comments p="8" id={post.id} title={post.title} url={location.href} />
        </Box>

        <Box as="aside" flex="1" w="100%" maxWidth={{ md: '300px' }}>
          <Sidebar />
        </Box>
      </Container>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      ...postFields
      fullBody {
        fullBody
        json
      }
      seo {
        ...seoFields
      }
    }
  }
`
