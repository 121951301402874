import React from 'react'
import Img from 'gatsby-image'
import { Flex, Link, Badge, Box, Heading } from '@pw/ui'

import RichText from '../RichText'
import SharePost from './Share'
import PostMeta from './Meta'

import './fragments'

const PostSingle = ({ className, post, location, ...props }) => {
  const fluidImage = post?.heroImage?.fluid ?? false
  const fullBody = post?.fullBody?.json ?? false
  const markdown = post?.body?.childMarkdownRemark?.html || ''
  const tags = post?.tags || []

  return (
    <Box mb="10" {...props}>
      <Box as="header" mb="4">
        <Heading
          as="h2"
          fontSize={{ md: '3xl', md: '4-5xl' }}
          color="green.700"
          textTransform="capitalize"
          lineHeight="1.1"
        >
          {post.title}
        </Heading>

        <PostMeta pl="1" post={post} />
      </Box>

      {fluidImage && <Img as={Img} fluid={fluidImage} />}

      <SharePost
        justifyContent={{ base: 'center', md: 'flex-start' }}
        post={post}
        location={location}
        pt="4"
      />

      <Box py="4">
        <RichText body={fullBody} markdown={markdown} />
      </Box>

      <Flex
        as="footer"
        py="4"
        px="10"
        my="4"
        alignItems="center"
        justifyContent="space-between"
        borderTop="1px"
        borderColor="gray.200"
      >
        <SharePost pt="4" />

        <Flex>
          {tags.map(({ slug, name, id }) => (
            <Badge
              key={id}
              as={Link}
              px="2"
              py="1"
              variant="outline"
              variantColor="green"
              to={`/tag/${slug}`}
              _hover={{ textDecoration: 'none' }}
              ml="1"
            >
              {name}
            </Badge>
          ))}
        </Flex>
      </Flex>
    </Box>
  )
}

export default PostSingle
